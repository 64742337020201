<template>
  <div class="auth-wrapper auth-v2 px-2">
    <showAt breakpoint="small">
      <div class="btn_prev_container">
          <div class="btn_prev_stage_mobile" style="margin-top: 20px;"> 
              <b-link :to="{name:'auth-login'}">
                  <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
              </b-link>
          </div>
      </div>
    </showAt>
    <div class="auth-inner py-2">
    <b-overlay
          :show="is_loading"
          spinner-variant="primary"
          variant="transparent"
          rounded="sm"
    >    
      <!-- Forgot Password v2 -->
      
      <b-card v-if="is_success" class="mb-0">
          
            <center>
              <showAt breakpoint="mediumAndAbove">
                <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
                    <b-link :to="{name:'auth-login'}">
                        <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                    </b-link>
                </div>
              </showAt>
              <b-img v-if="skin.value === 'light' " src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
              <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
            </center>
          
            <div class="d-flex" style="margin-top: 22px;">
                <Lottie 
                    :options="animationData" 
                    :height="100" 
                    :width="200" 
                />
            </div>
          
            <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 4px; font-size: 20px">
              {{$t('fogot.check-email')}}
            </b-card-title>
            <b-card-text class="mb-2 text-center text-muted" style="font-size: 14px;">
              {{$t('fogot.sent-link')}} {{ userEmail }}
            </b-card-text>
          
            <b-button 
              :to="{name:'auth-login'}" 
              variant="primary" 
              block 
              class="mt-2 submit-auth-v2"
              >
                {{$t('fogot.btns.log-in')}}
            </b-button>
         
          
      </b-card>
      
      <b-card v-else class="mb-0">
        
        <center>
          <showAt breakpoint="mediumAndAbove">
                <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
                    <b-link :to="{name:'auth-login'}">
                        <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                    </b-link>
                </div>
          </showAt>
          <b-img v-if="skin.value === 'light' " src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
          <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
        </center>
          
        <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 4px; font-size: 20px">
            {{$t('fogot.title')}}
             <b-img
                src="~@/assets/images/icons/emoji/lock.png"
                fluid
                alt="lock"
                style="width: 18px; height: 18px; margin-right: 3px; margin-left: 2px"
            ></b-img>
        </b-card-title>
        <b-card-text class="mb-2 text-center text-muted" style="font-size: 14px;">
          <i18n path="fogot.subtitle" tag="span">
            <br>
          </i18n>
        </b-card-text>

        <!-- form -->
        <validation-observer 
          ref="simpleRules"
          #default="{invalid}"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  :placeholder="$t('fogot.placeholders.email')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              class="submit-auth-v2"
              :disabled="invalid"
            >
              {{$t('fogot.btns.restore-password')}}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text v-if="is_error" class="text-center mt-2 text-danger">
            {{ err }}
        </b-card-text>
        
        <!-- <b-card-text class="text-center mt-1">
          <b-link :to="{name:'auth-login'}">
            Отмена
          </b-link>
        </b-card-text> -->

      </b-card>
    </b-overlay>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'

import useAppConfig from '@core/app-config/useAppConfig'
import * as SuccessAnimeView from "@/assets/anime/success.json"


export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
        
        userEmail: '',
        is_loading: false,
        is_success: false,
        is_error: false,

        err: "",
      
        animationData: { 
            animationData: SuccessAnimeView.default,
            loop: false
        }, 
      
        skin: useAppConfig().skin,

        // validation
        required,
        email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          
            this.is_loading = true;
            this.is_error = false;
            
            this.$request.post('user.passwordForgot', {
                email: this.userEmail
            }).then( result => {
                this.is_loading = false;
                this.is_success = true;
            }).catch(err => {
                this.is_loading = false;
                this.is_error = true;
                this.err = err.message;
            });
          
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
